<template>
  <div class="detail-supply-evaluation-list-container" v-loading="boxLoading">
    <div class="flex header-info">
      <el-descriptions class="show-cotainer" :column="4" :colon="false">
        <el-descriptions-item label="订单号:">
          <el-link type="primary" @click="handleGo">{{ detailsInfo.serviceNo }}</el-link>
        </el-descriptions-item>
        <el-descriptions-item label="服务供应商:">{{ detailsInfo.storeName }}</el-descriptions-item>
        <el-descriptions-item label="显示状态:">{{ detailsInfo.isHide ? '隐藏' : '显示' }}</el-descriptions-item>
        <el-descriptions-item></el-descriptions-item>
        <!-- v-if="!!detailsInfo.createTime" -->
        <el-descriptions-item label="评价时间:">{{ detailsInfo.createTime }}</el-descriptions-item>
        <!-- v-if="!!detailsInfo.evaluationReplyTime" -->
        <el-descriptions-item label="评价回复时间:">{{ detailsInfo.evaluationReplyTime }}</el-descriptions-item>
        <!-- v-if="!!detailsInfo.suggestReplyTime" -->
        <el-descriptions-item label="投诉建议回复时间:">{{ detailsInfo.suggestReplyTime }}</el-descriptions-item>
      </el-descriptions>
      <div class="flex header-btn-group">
        <el-button plain @click="handleShow" v-if="detailsInfo.isHide == 1">显示</el-button>
        <el-button plain @click="handleHide" v-if="detailsInfo.isHide == 0">隐藏</el-button>
        <!-- <el-button plain @click="handleDel">删除</el-button> -->
      </div>
    </div>
    <div class="hr"></div>
    <div v-if="detailsInfo.starMessage">
        <div class="detail-title">
        <span class="title-mark"></span>
        <span class="title-text">超星评价</span>      
      </div>
      <div class="super-start">
        <div style="display: flex;align-items: center;white-space: nowrap;color: #ccc;">满意点</div>
        <div style="margin-left: 35px;margin-top: 10px;">
          <div class="satisfied-point" v-for="(item, index) in detailsInfo.satisfactions" :key="index">{{item}}</div>
        </div>
      </div>
      <div class="super-start">
        <div style="display: flex;align-items: center;white-space: nowrap;color: #ccc;">超星留言</div>
        <div class="score" v-for="(item, key, i) in 1" :key="i" style="width: 100%;">
        <div class="score-content" >
          <div class="text" v-if="!!detailsInfo.starMessage">{{ detailsInfo.starMessage }}</div>
          <div class="text" style="color: #999" v-if="!detailsInfo.starMessage">暂无内容</div>
        </div>
        </div>
      </div>
    </div>
    <div class="detail-title">
      <span class="title-mark"></span>
      <span class="title-text">环境整体评分</span>
      <span class="title-text-show"><el-rate disabled allow-half :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']" v-model="detailsInfo.environmentScore"></el-rate></span>
    </div>
    <!-- <el-descriptions class="score-cotainer" :column="4">
      <el-descriptions-item v-for="(itemS, s) in surroundings" :key="s" :label="itemS.scoreItemName">
        <el-rate disabled allow-half :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']" v-model="itemS.score"></el-rate>
      </el-descriptions-item>
    </el-descriptions> -->
    <div class="detail-title">
      <span class="title-mark"></span>
      <span class="title-text">服务整体评分</span>
      <span class="title-text-show"><el-rate disabled allow-half :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']" v-model="detailsInfo.serviceScore"></el-rate></span>
    </div>
    <el-descriptions class="score-cotainer" :column="4">
      <el-descriptions-item v-for="(itemS, s) in serversRate" :key="s" :label="itemS.scoreItemName">
        <el-rate disabled allow-half :colors="['#E86247', '#E86247', '#E86247', '#E86247', '#E86247']" v-model="itemS.score"></el-rate>
      </el-descriptions-item>
    </el-descriptions>
    <div class="detail-title">
      <span class="title-mark"></span>
      <span class="title-text">评价</span>
    </div>
    <div class="score" v-for="(item, key, i) in 1" :key="i">
      <div class="score-content">
        <div class="text" v-if="!!detailsInfo.evaluation">{{ detailsInfo.evaluation }}</div>
        <div class="text" style="color: #999" v-if="!detailsInfo.evaluation">暂无内容</div>
        <span class="score-img" v-for="(itemj, index) in detailsInfo.evaluationPictureUrl" :key="index">
          <el-image class="certificate-img" :src="itemj" :preview-src-list="detailsInfo.evaluationPictureUrl" alt="" />
        </span>
      </div>
      <div class="reply-text" v-if="!!detailsInfo.evaluationReply">
        <span class="caption">供应商回复：</span>
        {{ detailsInfo.evaluationReply }}
      </div>
    </div>
    <div class="detail-title">
      <span class="title-mark"></span>
      <span class="title-text">投诉建议</span>
    </div>
    <div class="score" v-for="(item, key, i) in 1" :key="i">
      <div class="score-content">
        <div class="text" v-if="!!detailsInfo.suggest">{{ detailsInfo.suggest }}</div>
        <div class="text" style="color: #999" v-if="!detailsInfo.suggest">暂无内容</div>
        <span class="score-img" v-for="(item, key, i) in detailsInfo.suggestPictureUrl" :key="i">
          <el-image class="certificate-img" :src="item" :preview-src-list="detailsInfo.suggestPictureUrl" alt="" />
        </span>
      </div>

      <div class="reply-text" v-if="!!detailsInfo.suggestReply">
        <span class="caption">回复：</span>
        {{ detailsInfo.suggestReply }}
      </div>
    </div>
    <el-button class="submit-btn" type="primary" @click="handleReply" v-if="!detailsInfo.suggestReply">去回复</el-button>
    <el-button class="submit-btn" @click="handleModifyReply" v-else-if="!!detailsInfo.suggestReply">去修改回复</el-button>
  </div>
</template>
<script>
import { getDictionaryList } from '@/api/common';
import { getEvaluateDetail, changeEvaluateStatus, deleteEvaluate } from '@/api/evaluate';
export default {
  data() {
    return {
      id: undefined,
      boxLoading: false,
      detailsInfo: {},
      surroundingsMS: [], //环境评价-字典
      surroundings: [],
      serversRate: [],
    };
  },
  async created() {
    this.id = this.$route.query.id;
    this.getData();
    this.surroundingsMS = (await getDictionaryList({ data: { key: 'environment_score_type' } })).data.childs || [];
  },
  methods: {
    async getData() {
      try {
        this.boxLoading = true;
        this.detailsInfo = (await getEvaluateDetail({ data: this.id })).data || {};

        this.detailsInfo.evaluationPictureUrl = JSON.parse(this.detailsInfo.evaluationPictureUrl);
        this.detailsInfo.suggestPictureUrl = JSON.parse(this.detailsInfo.suggestPictureUrl);

        // 超星满意点
        if(this.detailsInfo.satisfactions) {
          this.detailsInfo.satisfactions = this.detailsInfo.satisfactions.split(",")
        }

        if (!this.detailsInfo.evaluationScore) {
          this.detailsInfo.evaluationScore = [];
        }
        this.detailsInfo.evaluationScore.forEach((e) => {
          if (e.scoreItemId <= 104 && e.scoreItemId >= 101) {
            this.surroundings.push(e);
          } else {
            this.serversRate.push(e);
          }
        });
        this.boxLoading = false;
      } catch (error) {
        this.boxLoading = false;
      }
    },
    handleBack() {
      this.$router.back();
    },
    handleGo() {
      window.history.pushState(null, null, `/index/service/serviceOrder/serviceParticulars?id=${this.detailsInfo.serviceId}`);
    },
    handleReply() {
      this.$router.push({ path: '/replyEvaluationList', query: { id: this.id, isModifyReply: true } });
    },
    handleModifyReply() {
      this.$router.push({ path: '/replyEvaluationList', query: { id: this.id, isModifyReply: false } });
    },
    async handleHide() {
      await changeEvaluateStatus({ data: this.detailsInfo.evaluationId });
      this.$notify.success({ title: '成功', message: '操作成功', duration: 2000 });
      this.getData();
    },
    async handleShow() {
      await changeEvaluateStatus({ data: this.detailsInfo.evaluationId });
      this.$notify.success({ title: '成功', message: '操作成功', duration: 2000 });
      this.getData();
    },
    async handleDel() {
      await deleteEvaluate({ data: this.detailsInfo.evaluationId });
      this.$notify.success({ title: '成功', message: '删除成功', duration: 2000 });
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-supply-evaluation-list-container {
  background-color: #fff;
  font-size: 14px;
  .header-info {
    justify-content: space-between;
  }
  .header-btn-group {
    padding: 24px 20px 24px 20px;
    align-items: flex-start;
  }
  .operate-btn {
    text-align: right;
  }
  .show-cotainer {
    padding: 24px 20px;
  }
  .hr {
    height: 16px;
    background-color: #f7f7f7;
  }
  .detail-title {
    display: -webkit-flex; /* 新版本语法: Chrome 21+ */
    display: -webkit-box; /* 老版本语法: Safari, iOS, Android browser, older WebKit browsers. */
    display: -moz-box; /* 老版本语法: Firefox (buggy) */
    display: -ms-flexbox; /* 混合版本语法: IE 10 */
    display: flex;
    align-items: center;
    color: #000000;
    padding: 11px 0;
    border-bottom: 1px solid #ededed;
    margin: 0 20px;
    .title-mark {
      width: 3px;
      height: 20px;
      margin: 0 20px 0 -20px;
      background-color: #363f47;
      border-radius: 0px 3px 3px 0px;
    }
    .title-text {
      margin-right: 20px;
    }
  }
  .score-cotainer {
    padding: 41px 36px;
  }
  .score {
    background: #f7f7f7;
    margin: 20px;
    .score-content {
      border-bottom: 1px solid #ededed;
      padding: 20px;
      .text {
        padding: 20px 0 20px 0;
      }
    }
    .score-img {
      margin-right: 16px;
    }
    .reply-text {
      padding: 20px;
      .caption {
        color: #9a9d9e;
      }
    }
  }
  .certificate-img {
    width: 100px;
    height: 100px;
  }
  .certificate-img:hover {
    cursor: pointer;
  }
  .submit-btn {
    margin: 0 20px 40px 20px;
  }
  .submit-btn-modify {
    margin-left: 0;
  }
}
.super-start {
  display: flex;
  padding-top: 20px;
  margin-left: 20px;
  &>span {
    color: #ccc;
  }
}

.satisfied-point {
  display: inline;
  padding: 5px 10px;
  margin: 0;
  margin-right: 10px;
  margin-top: 6px; 
  background-color: #fae0e3;
  float: left;
  margin-bottom: 12px;
  border-radius: 2px;
  // color: red;
  font-size: 12px;
}
</style>
